import {
  Settings,
  Users,
  CandidateList,
  FindCandidateList,
  NewCandidate,
  DepartmentsList,
  DeduplicationList,
  Planning,
  Profile,
  ReportTable,
  Calendar,
  PlanningDepartments,
  Analytics,
  OrgStructure,
  AgencyList,
  Integrations,
  Sources,
  Vacancies,
  WorkflowTabs,
  UserFieldsList
} from './Screens'

import {
  CANDIDATE_PAGE,
  CANDIDATES_PAGE,
  NEW_CANDIDATE_PAGE,
  FIND_CANDIDATE_PAGE,
  FIND_CANDIDATES_PAGE,
  PLANNING_PAGE,
  PLAN_PAGE,
  REPORT_PAGE,
  PROFILE_PAGE,
  SETTINGS_PAGE,
  USERS_PAGE,
  DEPARTMENTS_PAGE,
  DEPARTMENT_PAGE,
  PLANNING_EMPTY_PAGE,
  SELECTED_PLANNING_EMPTY_PAGE,
  CALENDAR_PAGE,
  PLANNING_PERIOD_PAGE,
  SELECTED_PLANNING_PERIOD_PAGE,
  ANALYTICS_PAGE,
  ORG_STRUCTURE_PAGE,
  PLANNING_DEPARTMENTS_PAGE,
  PLANNING_DEPARTMENT_PAGE,
  AGENCIES_PAGE,
  AGENCY_PAGE,
  DEDUPLICATION_PAGE,
  DEDUPLICATION_USER_PAGE,
  INTEGRATIONS_PAGE,
  SOURCES_PAGE,
  WORKFLOWS_PAGE,
  WORKFLOW_STATE_PAGE,
  VACANCIES_PAGE,
  VACANCY_PAGE,
  WORKFLOW_PAGE,
  USER_FIELDS_PAGE,
  USER_FIELD_PAGE
} from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'

const routes = ({ departmentName }) => [
  {
    path: CALENDAR_PAGE,
    name: 'Главная',
    component: Calendar,
    roles: [
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: CANDIDATES_PAGE,
    name: 'Список кандидатов', // 'Мои кандидаты' - для ролей: HM, partner, operationDirector, TU
    component: CandidateList,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: CANDIDATE_PAGE,
    component: CandidateList,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.partner,
      appConfig.roles.operationDirector,
      appConfig.roles.TU
    ]
  },
  {
    path: NEW_CANDIDATE_PAGE,
    name: 'Добавить кандидата',
    component: NewCandidate,
    roles: [appConfig.roles.recruiter, appConfig.roles.hiringManager] //, appConfig.roles.manager
  },
  {
    path: PLANNING_PERIOD_PAGE,
    component: PlanningDepartments,
    roles: [appConfig.roles.hiringManager]
  },
  {
    path: SELECTED_PLANNING_PERIOD_PAGE,
    component: PlanningDepartments,
    roles: [appConfig.roles.hiringManager]
  },
  {
    path: PLANNING_DEPARTMENTS_PAGE,
    name: 'Потребность персонала',
    component: PlanningDepartments,
    roles: [appConfig.roles.hiringManager]
  },
  {
    path: PLANNING_DEPARTMENT_PAGE,
    component: PlanningDepartments,
    roles: [appConfig.roles.hiringManager]
  },
  {
    path: PLANNING_PAGE,
    name: 'Планирование персонала',
    component: Planning,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.manager,
      appConfig.roles.admin,
      appConfig.roles.prioritySelector
    ]
  },
  {
    path: PLAN_PAGE,
    component: Planning,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.manager,
      appConfig.roles.admin,
      appConfig.roles.prioritySelector
    ]
  },
  {
    path: PLANNING_EMPTY_PAGE,
    name: `${departmentName} без плана`,
    component: Planning,
    roles: [appConfig.roles.manager, appConfig.roles.admin]
  },
  {
    path: SELECTED_PLANNING_EMPTY_PAGE,
    component: Planning,
    roles: [appConfig.roles.manager, appConfig.roles.admin]
  },
  {
    path: FIND_CANDIDATES_PAGE,
    // name: 'Поиск кандидата',
    component: FindCandidateList,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: FIND_CANDIDATE_PAGE,
    component: FindCandidateList,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: CANDIDATE_PAGE,
    component: FindCandidateList,
    roles: [appConfig.roles.admin, appConfig.roles.manager]
  },
  {
    path: REPORT_PAGE,
    name: 'Отчёт',
    component: ReportTable,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ]
  },
  {
    path: ANALYTICS_PAGE,
    name: 'Аналитика',
    component: Analytics,
    roles: [
      appConfig.roles.recruiter,
      appConfig.roles.hiringManager,
      appConfig.roles.manager,
      appConfig.roles.admin
    ],
    featureToggle: appConfig.features.analytics
  },
  {
    name: 'Настройки',
    roles: [appConfig.roles.admin],
    children: [
      {
        path: SETTINGS_PAGE,
        name: 'Система',
        component: Settings
      },
      {
        path: WORKFLOWS_PAGE,
        name: 'Воронка',
        component: WorkflowTabs,
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: USER_FIELDS_PAGE,
        name: 'Кандидат',
        component: UserFieldsList
      },
      {
        path: USER_FIELD_PAGE,
        component: UserFieldsList
      },
      {
        path: WORKFLOW_PAGE,
        component: WorkflowTabs,
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: WORKFLOW_STATE_PAGE,
        component: WorkflowTabs,
        featureToggle: appConfig.features.workflowDesigner
      },
      {
        path: VACANCIES_PAGE,
        name: 'Вакансии',
        component: Vacancies,
        featureToggle: appConfig.features.vacancyEditor
      },
      {
        path: VACANCY_PAGE,
        component: Vacancies,
        featureToggle: appConfig.features.vacancyEditor
      },
      {
        path: ORG_STRUCTURE_PAGE,
        name: 'Оргструктура',
        component: OrgStructure,
        featureToggle: appConfig.features.orgLayers
      },
      {
        path: INTEGRATIONS_PAGE,
        name: 'Интеграции',
        component: Integrations,
        featureToggle: appConfig.features.jobSites
      },
      {
        path: SOURCES_PAGE,
        name: 'Источники',
        component: Sources
      }
    ]
  },
  {
    path: USERS_PAGE,
    name: 'Пользователи',
    component: Users,
    roles: [appConfig.roles.admin]
  },
  {
    path: DEPARTMENTS_PAGE,
    name: departmentName,
    component: DepartmentsList,
    roles: [appConfig.roles.admin]
  },
  {
    path: DEPARTMENT_PAGE,
    component: DepartmentsList,
    roles: [appConfig.roles.admin]
  },
  {
    path: AGENCIES_PAGE,
    name: 'Агентства',
    component: AgencyList,
    roles: [appConfig.roles.admin],
    featureToggle: appConfig.features.agencies
  },
  {
    path: AGENCY_PAGE,
    component: AgencyList,
    roles: [appConfig.roles.admin],
    featureToggle: appConfig.features.agencies
  },
  {
    path: PROFILE_PAGE,
    name: 'Профиль',
    component: Profile,
    withoutRoles: true
  },
  {
    path: DEDUPLICATION_PAGE,
    name: 'Дедупликация',
    component: DeduplicationList,
    // только для рекрутеров без агентств
    roles: [appConfig.roles.recruiter],
    withoutAgency: true
  },
  {
    path: DEDUPLICATION_USER_PAGE,
    component: DeduplicationList,
    // только для рекрутеров без агентств
    roles: [appConfig.roles.recruiter],
    withoutAgency: true
  }
]
export default routes
