import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Spin } from 'antd'
import { UserContext } from '../../../contexts/userContext'
import UserAvatar from '../UserAvatar'

const { TextArea } = Input

function CommentForm({
  entity,
  comment,
  afterSave,
  avatarColSpan = 3,
  onCreate,
  onUpdate,
  saveButtonTitle = 'Сохранить'
}) {
  const [form] = Form.useForm()
  const { user } = useContext(UserContext)
  const isUpdate = Boolean(comment?._id)
  const [commentValue, setCommentValue] = useState('')

  useEffect(() => {
    if (isUpdate) {
      setCommentValue(comment.text?.trim())
      form.setFieldsValue({ comment: comment.text?.trim() })
    }
  }, [comment, form, isUpdate])

  const {
    mutate: createComment,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    isError: isErrorCreate
  } = onCreate()

  useEffect(() => {
    if (isSuccessCreate) {
      message.info('Комментарий создан')
      form.resetFields()
      afterSave?.()
    } else if (isErrorCreate) {
      message.error('Ошибка создания комментария')
    }
  }, [isSuccessCreate, isErrorCreate, form, afterSave])

  const {
    mutate: updateComment,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate
  } = onUpdate()

  useEffect(() => {
    if (isSuccessUpdate) {
      message.info('Комментарий изменен')
      afterSave?.()
    } else if (isErrorUpdate) {
      message.error('Ошибка изменения комментария')
    }
  }, [isSuccessUpdate, isErrorUpdate, afterSave])

  const handleSaveComment = () => {
    // так как этот компонент может использоваться внутри другой формы то валидируем вручную вместо  "<Form onFinish />"
    form.validateFields().then(values => {
      const mutateData = { id: entity._id, data: { text: values.comment } }
      if (isUpdate) {
        updateComment({ ...mutateData, commentId: comment._id })
      } else {
        createComment(mutateData)
      }
      setCommentValue('')
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setCommentValue('')
    afterSave?.()
  }

  return (
    <Spin spinning={isLoadingCreate || isLoadingUpdate}>
      <Form form={form} className="comment-form" onFinish={handleSaveComment}>
        <Row>
          <Col span={avatarColSpan}>
            <UserAvatar userName={user.name} size={35} />
          </Col>
          <Col span={24 - avatarColSpan}>
            <Form.Item name="comment" rules={[{ required: true, message: 'Введите комментарий' }]}>
              <TextArea
                placeholder="Комментарий"
                rows={2}
                value={commentValue}
                onChange={({ target }) => setCommentValue(target.value?.trim())}
                onBlur={() => {
                  if (!commentValue) {
                    form.resetFields()
                  }
                }}
              />
            </Form.Item>
            {commentValue && (
              <Button onClick={handleSaveComment} type="primary" size="small">
                {saveButtonTitle}
              </Button>
            )}
            {isUpdate && (
              <Button size="small" className="ml-3" onClick={handleCancel}>
                Отмена
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default CommentForm
