import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Layout, Button, Avatar, Col, Row, message } from 'antd'
import { UserContext } from '../../../../contexts/userContext'

import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { EXTERNAL_BUTTON, FORGOT_PASSWORD, PASSWORD_RECOVERY } from '../../../../constants/routes'
import { useMutateLogout } from '../../../../api/auth'
import CommonSearch from './CommonSearch'

const { Header: LayoutHeader } = Layout

const Header = ({ collapsed, toggle }) => {
  const { user, logout, isAdmin, isManager, isHiringManager, isRecruiter } = useContext(UserContext)
  const { mutate, isError, isLoading } = useMutateLogout()

  useEffect(() => {
    if (isError) {
      message.error('Ошибка выхода')
    }
  }, [isError])

  const handleClick = useCallback(() => {
    mutate()
    logout()
  }, [mutate, logout])

  // Don't show on Auth page
  const { pathname } = useLocation()
  if (
    pathname === '/' ||
    pathname === PASSWORD_RECOVERY ||
    pathname === EXTERNAL_BUTTON ||
    pathname === FORGOT_PASSWORD ||
    !Object.keys(user).length
  )
    return null

  return (
    <LayoutHeader className="header">
      <Row align="middle">
        <Col span={1} className="header-icons">
          {collapsed ? (
            <MenuUnfoldOutlined className="trigger" onClick={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </Col>
        {(isAdmin || isManager || isHiringManager || isRecruiter) && (
          <Col span={10} offset={1} className="search">
            <CommonSearch searchByButton />
          </Col>
        )}
        <Col span={12}>
          <div className="profile">
            <Avatar src="https://avatars1.githubusercontent.com/u/8186664?s=460&v=4" />
            <div>
              <div>{user.name}</div>
              <div>{user.department?.name}</div>
            </div>

            <Button
              type="primary"
              danger
              className="btn-exit"
              size="large"
              onClick={handleClick}
              loading={isLoading}
            >
              Выход
            </Button>
          </div>
        </Col>
      </Row>
    </LayoutHeader>
  )
}

export default Header

Header.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}
