import React, { useEffect, useMemo } from 'react'
import { message, Spin, Row, Col } from 'antd'

import { useAuthProfileMutate } from '../../../../api/users'

import AuthForm from '../../../../components/Common/AuthForm'
import { useProfile } from '../../../../hooks/useProfile'
import { useGetVersion } from '../../../../api/setting'

export default function Auth() {
  const { user, login } = useProfile()
  const { isLoading: isLoadingVersion, data: dataVersion } = useGetVersion()

  const version = useMemo(() => {
    if (isLoadingVersion) return null
    const [major, minor] = (dataVersion?.data?.toString() || '')?.split('.') || []
    return [major, minor].join('.')
  }, [isLoadingVersion, dataVersion])

  /**
   * Get Profile when Login will success
   */
  const {
    data: profile,
    status: profileStatus,
    isLoading: profileIsLoading,
    mutate: profileMutate
  } = useAuthProfileMutate()

  useEffect(() => {
    if (profileStatus === 'success' && profile.data) {
      login(profile.data)
    }
  }, [profileStatus, profile, login])

  /**
   * Errors from api requests
   */
  useEffect(() => {
    if (profileStatus === 'error') {
      message.error('Ошибка получения профиля')
    }
  }, [profileStatus])

  if (Object.keys(user || {})?.length) return null

  return (
    <>
      <Row justify="center" align="middle" className="Auth h-100">
        <Col span={12} className="logo-wrapper">
          <Row justify="center" align="middle">
            <Col className="logo" />
            <Col className="version">{version}</Col>
          </Row>
        </Col>
        <Col xs={20} sm={12}>
          <Spin spinning={profileIsLoading} size="large">
            <AuthForm onSuccess={profileMutate} />
          </Spin>
        </Col>
      </Row>
    </>
  )
}
