import { Button, Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { useCallback, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { findSettingsAtom } from '../../../../recoil/atoms'
import { FIND_CANDIDATES_PAGE } from '../../../../constants/routes'
import { useNavigate } from 'react-router-dom'
import { debounce } from '../../../../helpers'

const { Search } = Input

export default function CommonSearch() {
  const navigate = useNavigate()
  const [settings, setSettings] = useRecoilState(findSettingsAtom)
  const [search, setSearch] = useState(settings?.findNumber || '')

  const setContextSearch = useCallback(
    value => {
      if (value) {
        navigate(FIND_CANDIDATES_PAGE)
      }
      setSettings(oldValues => ({
        ...(oldValues || {}),
        findNumber: value,
        isSearchPageOpen: true
      }))
    },
    [setSettings, navigate]
  )

  const handleSearch = useCallback(
    value => {
      setContextSearch(value)
    },
    [setContextSearch]
  )
  const debounceSearch = useMemo(
    () =>
      debounce(value => {
        setContextSearch(value)
      }, 800),
    [setContextSearch]
  )

  const handleChangeSearch = useCallback(
    ({ target }) => {
      debounceSearch(target.value)
      setSearch(target.value)
    },
    [navigate, debounceSearch]
  )

  return (
    <Search
      type="tel"
      name="phone"
      size="large"
      placeholder="Поиск по телефону или ФИО"
      value={search}
      onSearch={handleSearch}
      onChange={handleChangeSearch}
      className=" "
      enterButton={
        <Button
          className="enterButton"
          disabled={!search}
          icon={
            <Tooltip title="введите полный номер телефона или ФИО чтобы получить более точные результаты">
              <SearchOutlined />
            </Tooltip>
          }
          size="small"
        />
      }
      allowClear
    />
  )
}
