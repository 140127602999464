import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Row, Spin, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { DEPARTMENTS_PAGE } from '../../../../constants/routes'
import { useMutateCreateDepartment } from '../../../../api/department'
import {
  CommentItem,
  DadataAddressItem,
  EmailItem
  // PhoneItem
} from '../FormItems/CandidateProfileItems'
import { DepartmentStatusItem } from '../FormItems/DepartmentItems'
import { colSettings } from '../../../../constants'
import { appConfig, langCases } from '../../../../constants/appConfig'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import CurrentOrgUnits from '../OrgStructure/Actions/CurrentOrgUnits'
import { getOrgUnitsIds } from '../../../../helpers/orgStructure'
import { ConfigContext } from '../../../../contexts/configContext'
import { tz } from '../../../../helpers/date-fns'
import AgenciesItemSelector from '../FormItems/AgenciesItemSelector/AgenciesItemSelector'
import { debounce } from '../../../../helpers'
import { useUsers } from '../../../../api/users'
import { EditingTitle } from '../index'
// import { PHONE_VALIDATION_MESSAGE } from '../../../../helpers/phone'

const { confirm } = Modal
const errorMessage = ({ roles, department, name }, departmentName) => {
  if (roles.includes(appConfig.roles.hiringManager)) {
    return `Пользователь "${name}" уже является директором ${departmentName?.toLowerCase()} "${
      department?.name || department
    }".`
  }
  return roles?.length === 1
    ? `Пользователь "${name}" имеет роль ${appConfig.rolesLabels[roles[0]]}.`
    : `У пользователя "${name}" неподходящая роль.`
}

function NewDepartment({ onOpenOrgUnits, onCancel, selectedOrgUnits }) {
  const { departmentType, getDepartmentSingularName } = useConfigContextData()
  const {
    features: { data: features }
  } = useContext(ConfigContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [address, setAddress] = useState(null)
  const [email, setEmail] = useState(null)

  const { mutate, isSuccess, isError, isLoading, error } = useMutateCreateDepartment()

  useEffect(() => {
    form.resetFields()
  }, [form])

  useEffect(() => {
    if (isSuccess) {
      navigate(DEPARTMENTS_PAGE)
    }
    if (isError) {
      let msg = 'Ошибка добавления орг. единицы'
      if (error?.response?.status === 409) {
        msg = error?.response?.data?.department
          ? `Пользователь "${
              error?.response?.data?.user
            }" уже является директором ${getDepartmentSingularName(
              langCases.genitive
            )?.toLowerCase()} "${error?.response?.data?.department}"`
          : 'У пользователя неподходящая роль'
      }
      message.error(msg)
    }
  }, [navigate, isSuccess, isError, error, getDepartmentSingularName])

  const { data: usersData, isError: isErrorUsers } = useUsers(
    {
      filters: {
        text: email
      }
    },
    {
      enabled: !!email
    }
  )

  useEffect(() => {
    let existUser = usersData?.data?.find(u => u.email === email)

    if (existUser) {
      form.setFields([
        {
          name: appConfig.roles.hiringManager + 'Email',
          errors: [errorMessage(existUser, getDepartmentSingularName(langCases.genitive))]
        }
      ])
    }
  }, [usersData?.data, email, form, getDepartmentSingularName])

  useEffect(() => {
    if (isErrorUsers) {
      message.error('Ошибка загрузки данных пользователей')
    }
  }, [isErrorUsers])

  const handleSave = useCallback(
    formData => {
      // адресс выбран из дадаты или адресс введен вручную
      if (
        !(
          address ||
          (formData.address && formData.longitude && formData.latitude && formData.timeZone)
        )
      ) {
        return form.setFields([
          { name: 'address', errors: ['Выберите адрес из списка или введите данные вручную'] }
        ])
      }
      const data = {
        name: formData.name,
        status: formData.status || appConfig.departmentStatusesKeys.opened,
        address: formData.address,
        phone: formData.phone,
        comment: formData.comment?.trim(),
        agencies: formData.agencies,
        city: address?.city || address?.settlement,
        hiringManager: {
          name: formData.hiringManager,
          email: formData.hiringManagerEmail
        },
        timeOffset:
          (formData.longitude &&
            formData.latitude &&
            formData.timeZone &&
            tz.find(z => z.name === formData.timeZone)?.offset) ||
          undefined,
        location: {
          type: 'Point',
          coordinates: [
            Number(address?.geo_lon || formData.longitude),
            Number(address?.geo_lat || formData.latitude)
          ]
        },
        parentOrganizationUnits: getOrgUnitsIds(selectedOrgUnits)
      }
      mutate({ data })
    },
    [form, mutate, address, selectedOrgUnits]
  )
  const handleFillAddress = useCallback(
    data => {
      if (!form.getFieldValue('name') && data?.city) {
        form.setFieldsValue({
          name: data?.city + (data?.street_with_type ? `, ${data?.street_with_type}` : '')
        })
      }
      form.setFields([{ name: 'address', errors: [] }])
      form.setFieldsValue({
        longitude: null,
        latitude: null,
        timeZone: null
      })
      setAddress(data)
    },
    [form]
  )

  const showConfirm = useCallback(
    formData => {
      confirm({
        title: 'Нет привязки к оргструктуре',
        icon: <ExclamationCircleOutlined />,
        content: `Данные ${getDepartmentSingularName(
          langCases.prepositional
        )?.toLowerCase()} будут созданы, но не будет привязки к оргструктуре`,
        okText: 'Подтвердить',
        cancelText: 'Отмена',
        onOk() {
          handleSave(formData)
        }
      })
    },
    [getDepartmentSingularName, handleSave]
  )

  const validateOrgUnitsAndPhone = useCallback(
    formData => {
      if (features?.includes(appConfig.features.orgLayers) && !selectedOrgUnits?.length) {
        showConfirm(formData)
      } else {
        // if (formData.phone?.replace(/\D/g, '')) {
        //   if (formData.phone.replace(/\D/g, '').length === 11) {
        //     handleSave(formData)
        //   } else {
        //     return form.setFields([{ name: 'phone', errors: [PHONE_VALIDATION_MESSAGE] }])
        //   }
        if (formData.phone) {
          handleSave(formData)
        } else {
          handleSave({ ...formData, phone: null })
        }
      }
    },
    [handleSave, selectedOrgUnits, showConfirm, features /*, form*/]
  )

  const handleResetAddress = useCallback(() => setAddress(null), [])
  const handleOpenOrgUnits = useCallback(
    () => onOpenOrgUnits?.(selectedOrgUnits),
    [onOpenOrgUnits, selectedOrgUnits]
  )

  const debounceCheckEmail = useMemo(() => debounce(value => setEmail(value), 800), [])

  return (
    <div className="drawer__wrap">
      <Spin spinning={isLoading}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={validateOrgUnitsAndPhone}
          className="drawer-content"
        >
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <div className="block-title no-gutters">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Введите короткое название'
                    }
                  ]}
                >
                  <EditingTitle
                    isNew={!form.getFieldValue('name')}
                    displayName={form.getFieldValue('name') || `НОВЫЙ ${departmentType}`}
                    onEdit={value => form.setFieldsValue({ name: value })}
                    isResetEditing={isLoading}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider className="small" />
          <div className="content">
            <div className="sub-title">
              Информация {getDepartmentSingularName(langCases.prepositional)?.toLowerCase()}
            </div>
            <CurrentOrgUnits onOpen={handleOpenOrgUnits} selectedOrgUnits={selectedOrgUnits} />
            {/*<Row>*/}
            {/*  <Col span={24}>*/}
            {/*    <Form.Item*/}
            {/*      hidden*/}
            {/*      labelCol={colSettings.full}*/}
            {/*      wrapperCol={colSettings.full}*/}
            {/*      name="name"*/}
            {/*      rules={[*/}
            {/*        {*/}
            {/*          required: true,*/}
            {/*          message: 'Введите короткое название'*/}
            {/*        }*/}
            {/*      ]}*/}
            {/*    >*/}
            {/*      <Input placeholder="Введите короткое название" />*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col span={24}>
                <DadataAddressItem
                  onChange={handleFillAddress}
                  onResetAddress={handleResetAddress}
                  label=""
                  isHandleAddress
                  form={form}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DepartmentStatusItem label="" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item labelCol={colSettings.full} wrapperCol={colSettings.full} name="phone">
                  <Input placeholder="Введите номер телефона" />
                </Form.Item>
                {/*<PhoneItem placeholder="Введите номер телефона" />*/}
              </Col>
            </Row>
            <div className="sub-title">{appConfig.rolesLabels[appConfig.roles.hiringManager]}</div>
            <Row>
              <Col span={24}>
                <Form.Item
                  labelCol={colSettings.full}
                  wrapperCol={colSettings.full}
                  name={appConfig.roles.hiringManager}
                  rules={[
                    {
                      required: true,
                      message: 'Введите фамилию'
                    }
                  ]}
                >
                  <Input placeholder="Введите фамилию" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <EmailItem
                  name={appConfig.roles.hiringManager + 'Email'}
                  label=""
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => {
                        debounceCheckEmail(value)
                        return Promise.resolve()
                      }
                    }
                  ]}
                />
              </Col>
            </Row>
          </div>
          <AgenciesItemSelector label="Ответственные агентства" form={form} size="middle" />
          <div className="sub-title">Комментарий</div>
          <CommentItem label="" placeholder="Комментарий" />

          <div className="drawer-footer">
            <Divider className="small" />
            <Row className="buttons">
              <Col>
                <Button
                  type="link"
                  className="mr-3"
                  onClick={() => onCancel?.()}
                  disabled={isLoading}
                >
                  Отмена
                </Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Сохранить
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default NewDepartment
